import request from '@/utils/request';

// 客户列表
export function getCustom(data) {
    return request({
        url: `/control/chance/list?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
        method: 'post',
        data
    });
}

// 导出客户列表
export function exportCustom(data) {
    return request({
        url: '/control/chance/export',
        method: 'post',
        data,
        responseType:'arraybuffer'
    });
}
