<template>
  <div>
    <page-main>
      <div class="main-body-header" slot="header">
        <span class="main-title">搜索</span>
      </div>
      <div slot="body">
        <el-row :gutter="15">
          <el-col :span="6">
            <span>客户姓名</span>
            <el-input
              size="mini"
              style="width: 60%"
              class="form-table_inpse"
              placeholder="客户姓名"
              v-model="parmas.name"
              @keyup.enter.native="getCustom"
            >
            </el-input>
          </el-col>
          <el-col :span="6">
            <span>客户电话</span>
            <el-input
              size="mini"
              style="width: 60%"
              class="form-table_inpse"
              placeholder="客户电话"
              v-model="parmas.phone"
              @keyup.enter.native="getCustom"
            >
            </el-input>
          </el-col>
          <el-col :span="6">
            <span>公司名称</span>
            <el-input
              size="mini"
              style="width: 60%"
              class="form-table_inpse"
              placeholder="公司名称"
              v-model="parmas.corporateName"
              @keyup.enter.native="getCustom"
            >
            </el-input>
          </el-col>
          <el-col :span="6">
            <span>公司地址</span>
            <el-input
              size="mini"
              style="width: 60%"
              class="form-table_inpse"
              placeholder="公司地址"
              v-model="parmas.area"
              @keyup.enter.native="getCustom"
            >
            </el-input>
          </el-col>
        </el-row>
      </div>
    </page-main>
    <page-main>
      <div class="main-body-header" slot="header">
        <span class="main-title">客户信息</span>
        <el-button size="mini" type="primary" @click="exportCustom">导出</el-button>
      </div>
      <div slot="body">
        <el-table :data="tableData" v-loading="loading" style="font-size: 14px" height="450px">
          <el-table-column label="#" width="40" type="index" />
          <el-table-column
            label="客户姓名"
            prop="name"
            width="250"
            :show-overflow-tooltip="true"
          />
          <el-table-column label="电话" prop="phone" :show-overflow-tooltip="true" />
          <el-table-column
            label="公司名称"
            prop="corporateName"
            :show-overflow-tooltip="true"
          />
          <el-table-column label="公司地址" prop="area" :show-overflow-tooltip="true" />
          <el-table-column label="邮箱" prop="email" :show-overflow-tooltip="true" />
          <el-table-column label="录入时间" prop="createTime" :show-overflow-tooltip="true" />
        </el-table>
        <div class="pagination" style="margin-top: 0px; float: right">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="parmas.pageNum"
            :page-sizes="[50, 100, 150, 200]"
            :page-size="parmas.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageTotal"
          >
          </el-pagination>
        </div>
      </div>
    </page-main>
  </div>
</template>
<script>
import PageMain from "@/components/pageMain/PageMain.vue";
import { getCustom, exportCustom } from "@/api/dataSet/customManagment";
export default {
  components: {
    PageMain,
  },
  data() {
    return {
      tableData: [],
      parmas: {
        name: "",
        phone: "",
        corporateName: "",
        area: "",
        pageNum: 1,
        pageSize: 50,
      },
      selectElmentId: null,
      pageTotal: null,
      loading: false,
    };
  },
  mounted() {
    this.getCustom();
  },
  methods: {
    async getCustom() {
      this.loading = true;
      let res = await getCustom(this.parmas);
      if (res.code === 200) {
        this.tableData = res.rows;
        this.pageTotal = res.total;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },

    //导出客户信息
    async exportCustom() {
      let res = await exportCustom({});
      if (res) {
        this.isExcel("xlsx", "客户列表", res);
      }
    },

    isExcel(type, name, data) {
      const link = document.createElement("a");
      const blob = new Blob([data]);
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", `${name}.${type}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleSizeChange(val) {
      this.parmas.pageSize = val;
      this.getmaterialList();
    },
    handleCurrentChange(val) {
      this.parmas.pageNum = val;
      this.getmaterialList();
    },
  },
};
</script>
<style scoped lang="scss">
@import "../common.scss";
</style>
